import { Button, Modal, Result } from "antd";
import { useEffect, useState } from "react";
import styles from "./PrivateContentPopup.module.scss";

const PrivateContentPopup = () => {
  const [show, setShow] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const close = () => {
    setShow(false);
    setShowPopup(false);
  };

  return (
    <div className={styles.container}>
      {show ? <div className={styles.blur}></div> : null}
      <Modal open={showPopup} onOk={close} onCancel={close} footer={<></>}>
        <Result
          status="warning"
          title={
            <div>
              <h5>Halaman ini berisi informasi rahasia</h5>
              pastikan anda tidak sedang menunjukan halaman ini di live stream
              atau konten
            </div>
          }
          extra={
            <Button type="primary" onClick={close}>
              Saya mengerti
            </Button>
          }
        />
      </Modal>
    </div>
  );
};

export default PrivateContentPopup;
