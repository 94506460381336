import styles from "./Login.module.scss";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Breadcrumb, Card, Layout, Menu, theme } from "antd";
import { LoginResponse } from "../../types";
import { useNavigate } from "react-router-dom";
import { backendAPI } from "../../util/api";
import { Header } from "antd/es/layout/layout";
import { Helmet } from "react-helmet";

const { Content, Footer } = Layout;

function Login() {
  const navigate = useNavigate();
  const login = async (creds: CredentialResponse) => {
    const response = await backendAPI.post<LoginResponse>(
      (process.env.REACT_APP_API_BASE as string) + "/auth/login",
      {
        id_token: creds.credential,
      }
    );

    localStorage.setItem("session", response.data.token);
    navigate("/admin");
    window.location.reload();
  };
  const onNavigate = (menu: string) => {
    navigate(menu);
  };

  return (
    <Layout className="layout">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Header
        className={styles.header}
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#07264c",
        }}
      >
        <div className={styles.logo}>
          <img src="/logo-stacked.svg" />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={(menu) => onNavigate(menu.key)}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#07264c",
          }}
          items={[
            {
              key: "/",
              label: `Home`,
            },
          ]}
        />
      </Header>
      <Content className={styles.contentContainer}>
        <div></div>
        <div className={styles.main}>
          <Card className={styles.card}>
            <h3>Login Admin Antree-an</h3>
            <GoogleLogin
              onSuccess={login}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </Card>
        </div>
        <div></div>
      </Content>
      <Footer>
        <h5>Powered by Antree</h5>
      </Footer>
    </Layout>
  );
}

export default Login;
