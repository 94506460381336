import { Button, Collapse, Descriptions, Tabs } from "antd";
import PrivateContentPopup from "../../components/PrivateContentPopup";
import MainLayout from "../../layout/mainLayout";
import { useProfile } from "../../context/UserProfile.context";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import styles from "./Settings.module.scss";
import { useEffect, useState } from "react";
// import SaweriaMd from "../../../public/docs/saweria.md";
// import SociabuzzMd from "../../../public/docs/sociabuzz.md";
import Markdown from "react-markdown";

const SettingsPage = () => {
  const profileContext = useProfile();
  const [tutorialState, setTutorialState] = useState({
    saweria: "",
    sociabuzz: "",
  });

  useEffect(() => {
    Promise.allSettled([
      fetch("/docs/saweria.md").then((res) => res.text()),
      fetch("/docs/sociabuzz.md").then((res) => res.text()),
    ]).then(([saweria, sociabuzz]) => {
      setTutorialState((prev) => ({
        sociabuzz: sociabuzz.status === "fulfilled" ? sociabuzz.value : "",
        saweria: saweria.status === "fulfilled" ? saweria.value : "",
      }));
    });
  }, []);
  return (
    <MainLayout>
      <PrivateContentPopup />
      <Descriptions
        title="URL Webhook"
        layout="horizontal"
        size="small"
        column={1}
      >
        <Descriptions.Item
          label="Sociabuzz"
          contentStyle={{
            fontSize: "smaller",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <div
            className={styles.link}
          >{`${process.env.REACT_APP_WEB_BASE}/hook/sociabuzz/${profileContext?.id}`}</div>
          <CopyToClipboard
            text={`${process.env.REACT_APP_WEB_BASE}/hook/sociabuzz/${profileContext?.id}`}
            onCopy={() => {}}
          >
            <Button size="small">
              <CopyOutlined /> copy link
            </Button>
          </CopyToClipboard>
        </Descriptions.Item>
        <Descriptions.Item
          label="Saweria"
          contentStyle={{
            fontSize: "smaller",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <div
            className={styles.link}
          >{`${process.env.REACT_APP_WEB_BASE}/hook/saweria/${profileContext?.id}`}</div>
          <CopyToClipboard
            text={`${process.env.REACT_APP_WEB_BASE}/hook/saweria/${profileContext?.id}`}
            onCopy={() => {}}
          >
            <Button size="small">
              <CopyOutlined /> copy link
            </Button>
          </CopyToClipboard>
        </Descriptions.Item>
      </Descriptions>
      <Collapse
        accordion
        items={[
          {
            key: "1",
            label: "Tutorial Setup Saweria",
            children: (
              <div className={styles.mdcontent}>
                <Markdown>{tutorialState.saweria}</Markdown>
              </div>
            ),
          },
          {
            key: "2",
            label: "Tutorial Setup Sociabuzz",
            children: (
              <div className={styles.mdcontent}>
                <Markdown>{tutorialState.sociabuzz}</Markdown>
              </div>
            ),
          },
        ]}
      />
    </MainLayout>
  );
};

export default SettingsPage;
