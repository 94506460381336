import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./QueueAdmin.module.scss";
import {
  Button,
  Card,
  Descriptions,
  Empty,
  Flex,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  Modal,
  Result,
  Switch,
  Tabs,
  Tooltip,
  message,
} from "antd";
import { Queue, QueueDetail } from "../../types";
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import QueueDetailTab from "./QueueDetail";
import useWebSocket from "react-use-websocket";
import _ from "lodash";
import antreeAPIs from "../../api/atreeAPI";
import { thousandSeparator } from "../../util/general";
import MainLayout from "../../layout/mainLayout";
import { Helmet } from "react-helmet";

function Home() {
  const [queueState, setQueueState] = useState<QueueDetail[]>([]);

  const [newQueueState, setNewQueueState] = useState<{
    showPopup: boolean;
    loading: boolean;
    data: Partial<QueueDetail>;
  }>({
    showPopup: false,
    loading: false,
    data: {},
  });
  const [deletePopup, setDeletePopup] = useState<QueueDetail | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const { lastMessage } = useWebSocket(
    `${
      process.env.REACT_APP_WEBHOOK_BASE as string
    }/ws-init?token=${localStorage.getItem("session")}&as=admin`
  );
  useEffect(() => {
    let messageJson = {} as any;

    try {
      messageJson = JSON.parse(lastMessage?.data);
    } catch (error) {}

    if (messageJson.type === "queue") {
      setQueueState((prev) => {
        const queueIndex = prev.findIndex((t) => t.id === messageJson.queueId);

        if (queueIndex < 0) return _.cloneDeep(prev);
        prev[queueIndex].queue = messageJson?.data;
        return _.cloneDeep(prev);
      });
    }
  }, [lastMessage]);

  const reload = () => {
    antreeAPIs
      .getMyQueue()
      .then((res) => {
        setQueueState(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    antreeAPIs
      .getMyQueue()
      .then((res2) => {
        setQueueState(res2.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const onQueueUpdated = React.useRef(
    _.debounce((id: string, queue: Queue[]) => {
      try {
        antreeAPIs.updateQueue(id, queue).catch(() => {});
      } catch (error) {}
    }, 1000)
  ).current;
  React.useEffect(() => {
    return () => {
      onQueueUpdated.cancel();
    };
  }, [onQueueUpdated]);

  const onSaveQueue = () => {
    if (
      !newQueueState.data.name ||
      !newQueueState.data.command ||
      !newQueueState.data.price
    ) {
      messageApi.warning("isikan data nama, command dan harga");
      return;
    }

    if (newQueueState.data.command.includes(" ")) {
      messageApi.warning(
        <span>
          <strong>command</strong> tidak boleh mengandung spasi
        </span>
      );
      return;
    }

    setNewQueueState((prev) => ({ ...prev, loading: true }));
    const command = "/" + newQueueState.data.command;
    if (newQueueState.data.id) {
      antreeAPIs
        .updateQueueDetail(newQueueState.data.id, {
          ...newQueueState.data,
          command,
        })
        .then(() => {
          setNewQueueState({
            loading: false,
            showPopup: false,
            data: {},
          });
          reload();
        })
        .catch(() => {
          messageApi.error("gagal menyimpan antree-an");
        });
    } else {
      antreeAPIs
        .createQueue({
          ...newQueueState.data,
          command,
        })
        .then(() => {
          setNewQueueState({
            loading: false,
            showPopup: false,
            data: {},
          });
          reload();
        })
        .catch(() => {
          messageApi.error("gagal menyimpan antree-an");
        });
    }
  };

  const onCancelCreateQueue = () => {
    setNewQueueState({
      showPopup: false,
      loading: false,
      data: {},
    });
  };

  const addNewQueue = () => {
    setNewQueueState({
      showPopup: true,
      loading: false,
      data: {
        isActive: true,
        fields: [
          {
            name: "id",
            showOnOverlay: true,
          },
        ],
        mergeSimilar: true,
      },
    });
  };

  const onFormChange = (field: keyof QueueDetail, value: any) => {
    setNewQueueState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [field]: value,
      },
    }));
  };

  const onAddField = () => {
    setNewQueueState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        fields: [
          ...(prev.data.fields ?? []),
          { name: "", showOnOverlay: true },
        ],
      },
    }));
  };
  const onChangeField = (
    field: "name" | "showOnOverlay",
    value: any,
    name: string
  ) => {
    setNewQueueState((prev) => {
      const fieldIndex =
        prev.data.fields?.findIndex((t) => t.name === name) ?? -1;
      if (fieldIndex < 0) return prev;
      if (!prev.data.fields) return prev;

      _.set(prev.data.fields[fieldIndex], field, value);

      return _.cloneDeep(prev);
    });
  };
  const onFieldDeleted = (name: string) => {
    setNewQueueState((prev) => {
      if (!prev.data.fields) return prev;
      return _.cloneDeep({
        ...prev,
        data: {
          ...prev.data,
          fields: prev.data.fields.filter((t) => t.name !== name),
        },
      });
    });
  };

  const onSettings = (id: string) => {
    const queue = queueState.find((t) => t.id === id);
    if (!queue) return;
    setNewQueueState({
      showPopup: true,
      loading: false,
      data: { ...queue, command: queue.command.replace("/", "") },
    });
  };

  const onDelete = (id: string) => {
    const queue = queueState.find((t) => t.id === id);
    if (!queue) return;
    setDeletePopup(queue);
  };

  const onDeleteQueue = () => {
    if (!deletePopup?.id) return;
    antreeAPIs.deleteQueue(deletePopup.id).then(() => {
      setDeletePopup(null);
      reload();
    });
  };

  const onCancelDeleteQueue = () => {
    setDeletePopup(null);
  };

  const onRowAction = (
    detailId: string,
    queueId: string,
    action: "add" | "subtract" | "delete"
  ) => {
    const index = queueState.findIndex((t) => t.id === detailId);

    if (index < 0) return;

    const queueIndex =
      queueState[index].queue?.findIndex((t) => t.id === queueId) ?? -1;

    if (index < 0) return;
    setQueueState((prev) => {
      const newState = _.cloneDeep(prev);

      switch (action) {
        case "add":
          newState[index].queue[queueIndex].qty =
            newState[index].queue[queueIndex].qty + 1;
          break;
        case "subtract":
          if (newState[index].queue[queueIndex].qty - 1 <= 0) {
            newState[index].queue.splice(queueIndex, 1);
          } else {
            newState[index].queue[queueIndex].qty =
              newState[index].queue[queueIndex].qty - 1;
          }
          break;
        case "delete":
          newState[index].queue = newState[index].queue.splice(queueIndex, 1);
          break;
      }

      onQueueUpdated(detailId, newState[index].queue);
      return newState;
    });
  };

  const onNewItemAdded = (detailId: string, at: number, newItem: Queue) => {
    const index = queueState.findIndex((t) => t.id === detailId);

    if (index < 0) return;

    setQueueState((prev) => {
      const newState = _.cloneDeep(prev);

      newState[index].queue.splice(at, 0, newItem);

      onQueueUpdated(detailId, newState[index].queue);
      return newState;
    });
  };

  const onItemMoved = (queueId: string, item: Queue, moveTo: number) => {
    const index = queueState.findIndex((t) => t.id === queueId);

    if (index < 0) return;

    const queueIndex =
      queueState[index].queue?.findIndex((t) => t.id === item.id) ?? -1;

    if (queueIndex < 0) return;

    setQueueState((prev) => {
      const newState = _.cloneDeep(prev);

      newState[index].queue.splice(queueIndex, 1, null as any);
      newState[index].queue.splice(moveTo, 0, _.cloneDeep(item));
      newState[index].queue = newState[index].queue.filter(Boolean);

      onQueueUpdated(queueId, newState[index].queue);
      return newState;
    });
  };

  return (
    <MainLayout>
      <Helmet>
        <title>Admin Antreean</title>
      </Helmet>
      {contextHolder}
      <Modal
        open={!!deletePopup}
        onOk={onDeleteQueue}
        onCancel={onCancelDeleteQueue}
        style={{ display: "flex", flexDirection: "column" }}
        className={styles.form}
      >
        Apakah anda yakin ingin menghapus antree-an{" "}
        <strong>{deletePopup?.name}</strong> ?
      </Modal>
      <Modal
        title={newQueueState.data.id ? "Edit" : "Tambah"}
        open={newQueueState.showPopup}
        onOk={onSaveQueue}
        confirmLoading={newQueueState.loading}
        onCancel={onCancelCreateQueue}
        style={{ display: "flex", flexDirection: "column" }}
        className={styles.form}
      >
        <Form layout="vertical" wrapperCol={{ span: 24 }} autoComplete="off">
          <Form.Item
            label="Nama Antree-an"
            rules={[{ required: true, message: "inputkan nama antree-an!" }]}
            required
          >
            <Input
              value={newQueueState.data.name}
              onChange={(e) => onFormChange("name", e.target.value)}
            />
          </Form.Item>

          <Form.Item
            required
            label={
              <div>
                Command{" "}
                <Tooltip
                  placement="topLeft"
                  title="digunakan untuk awalan pesan donasi agar terdeteksi masuk antrian. contoh : /mabar id=123123"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[{ required: true, message: "inputkan command!" }]}
          >
            <Input
              value={newQueueState.data.command}
              prefix={<span>/</span>}
              onChange={(e) => onFormChange("command", e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Harga"
            rules={[{ required: true, message: "inputkan harga!" }]}
            style={{ width: "100%" }}
            required
          >
            <InputNumber
              style={{ width: "100%" }}
              prefix={"Rp"}
              formatter={(value) => `${thousandSeparator(value ?? 0)}`}
              parser={(value) =>
                parseInt(
                  value?.toString().replaceAll(".", "").replaceAll("Rp ", "") ??
                    "0"
                )
              }
              value={newQueueState.data.price ?? 0}
              onChange={(e) => onFormChange("price", e)}
            />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Data Tambahan{" "}
                <Tooltip
                  placement="topLeft"
                  title="digunakan untuk menyimpan data tambahan dalam antrian. contoh untuk menyimpan id dan server game, buat data tambahan dengan nama id dan server. maka pesan donasi dapat diformat sbb /mabar id=123123&server=ina"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
            style={{
              width: "100%",
            }}
          >
            <div className={styles.fields}>
              {newQueueState.data.fields?.map((t) => (
                <div>
                  <Input
                    placeholder="nama data"
                    onChange={(e) =>
                      onChangeField("name", e.target.value, t.name)
                    }
                    value={t.name}
                    style={{ width: "10rem" }}
                  />
                  <Tooltip
                    placement="topLeft"
                    title={
                      t.showOnOverlay
                        ? "non-aktifkan untuk menyembunyikan data ini pada overlay"
                        : "aktifkan untuk menampilkan data ini pada overlay"
                    }
                  >
                    <Switch
                      size="small"
                      checked={t.showOnOverlay}
                      onChange={(e) =>
                        onChangeField("showOnOverlay", e, t.name)
                      }
                    />
                  </Tooltip>
                  <Button size="small" onClick={() => onFieldDeleted(t.name)}>
                    <DeleteOutlined />
                  </Button>
                </div>
              ))}
              <Button size="small" onClick={onAddField}>
                <PlusOutlined />
              </Button>
            </div>
          </Form.Item>

          <Form.Item style={{ width: "100%" }} label="Status Antrian">
            <Switch
              checkedChildren="aktif"
              unCheckedChildren="non aktif"
              onChange={(e) => onFormChange("isActive", e)}
              checked={newQueueState.data.isActive}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label={
              <div>
                Gabung Antrian yang Sama{" "}
                <Tooltip
                  placement="topLeft"
                  title="aktifkan untuk menggabungkan antrian dengan email yang sama (menambah quantity/slot antrian yang sudah ada)"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
          >
            <Switch
              size="default"
              onChange={(e) => onFormChange("mergeSimilar", e)}
              checked={newQueueState.data.mergeSimilar}
            />
          </Form.Item>
        </Form>
      </Modal>
      {queueState.length > 0 ? (
        <Button type="primary" onClick={addNewQueue}>
          <UsergroupAddOutlined /> Tambah Antree-an Baru
        </Button>
      ) : null}
      {queueState.length === 0 ? (
        <Empty
          description={
            <div>
              <p>Anda belum memiliki antree-an</p>
              <Button type="primary" onClick={addNewQueue}>
                Buat Antree-an baru sekarang
              </Button>
            </div>
          }
        />
      ) : (
        <Tabs
          items={(queueState ?? []).map((t) => ({
            key: t.id,
            label: t.name,
            children: (
              <QueueDetailTab
                data={t}
                onEdit={onSettings}
                onDelete={onDelete}
                onRowAction={onRowAction}
                onAdded={onNewItemAdded}
                onItemMoved={onItemMoved}
              />
            ),
          }))}
        ></Tabs>
      )}
    </MainLayout>
  );
}

export default Home;
