export type LoginResponse = {
  id: string;
  name: string;
  email: string;
  picture: string;
  token: string;
};

export type ProfileResponse = {
  id: string;
  email: string;
  name: string;
  picture: string;
  subscriptionStatus: SubscriptionStatus;
  createdAt: string;
  updatedAt: string;
};

export type QueueDetail = {
  id: string;
  name: string;
  command: string;
  fields: { name: string; showOnOverlay: boolean }[];
  price: number;
  max: any;
  ownerId: string;
  queue: Queue[];
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  mergeSimilar: boolean;
};

export type Queue = {
  id: string;
  email: string;
  name: string;
  data: any;
  qty: number;
  totalDonate: number;
  at: number;
  message: string;
};

export enum SubscriptionStatus {
  ACTIVE = 0,
  CANCELED = 1,
  EXPIRED = 2,
  INACTIVE = 3,
}
