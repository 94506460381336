import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { QueueDetail } from "../../types";
import antreeAPIs from "../../api/atreeAPI";
import styles from "./Horizontal.module.scss";
import useWebSocket from "react-use-websocket";
import _ from "lodash";

const HorizontalOverlay = () => {
  let [search] = useSearchParams();
  const params = useParams();
  const [queue, setQueue] = useState<QueueDetail | null>(null);

  useEffect(() => {
    if (!params.queueId) return;

    antreeAPIs.getQueueById(params.queueId).then((res) => setQueue(res.data));
  }, [params.queueId]);

  const showedFields = queue?.fields
    .filter((t) => t.showOnOverlay)
    .map((t) => t.name);

  const { lastMessage } = useWebSocket(
    `${
      process.env.REACT_APP_WEBHOOK_BASE as string
    }/ws-init?token=${localStorage.getItem("session")}&as=overlay&id=${
      params.queueId
    }`
  );
  useEffect(() => {
    let messageJson = {} as any;

    try {
      messageJson = JSON.parse(lastMessage?.data);
    } catch (error) {}

    if (messageJson.type === "queue") {
      setQueue((prev) =>
        prev ? _.cloneDeep({ ...prev, queue: messageJson.data }) : null
      );
    }
  }, [lastMessage]);

  return (
    <div
      className={styles.container}
      style={{
        color: search.get("color") ?? "white",
        fontWeight: search.get("fontweight") ?? "normal",
        textShadow:
          search.get("border") === "true"
            ? `-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000`
            : "none",
      }}
    >
      <div>
        antrian {queue?.name} (
        {(queue?.queue ?? []).reduce((pv, curr) => curr.qty + pv, 0)})
      </div>
      <div className={styles.list}>
        <ol>
          {(queue?.queue ?? []).map((t, i) => (
            <li key={t.id}>
              <span>◆ {i + 1}. </span>
              {t.name} -{" "}
              {showedFields
                ?.map((f) => [f, t.data[f]])
                .filter(([, v]) => !!v)
                .map(([f, v]) => `${f}: ${v}`)
                .join(", ")}{" "}
              - {t.qty} slot
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default HorizontalOverlay;
