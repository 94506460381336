import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE;

export const getToken = () => localStorage.getItem("session") ?? null;

export const getAuthorizationHeader = () => {
  const token = getToken();

  return token ? `Bearer ${token}` : undefined;
};

export const backendAPI = axios.create({
  baseURL,
  headers: { Authorization: getAuthorizationHeader() },
});
