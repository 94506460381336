import { Button } from "antd";
import HomeLayout from "../../layout/homeLayout";
import styles from "./Home.module.scss";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <HomeLayout>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className={styles.container}>
        <img src="/queue-illustration.png" className={styles.bg} />
        <h1>
          Budayakan <strong className={styles.online}>Antree</strong>{" "}
          <strong>Online</strong>
        </h1>
        <p>
          "<strong className={styles.online}>Antree</strong>" di mana saja,
          darimana saja, kapan saja. alternatifnya antre bikin barisan, apalagi
          antre titip sendal
        </p>
        <p>
          "<strong className={styles.online}>Antree</strong>" adalah budaya
        </p>
        <Button className={styles.cta} type="primary" href="/admin">
          Buat Antree-an Online
        </Button>
      </div>
    </HomeLayout>
  );
};

export default Home;
