import React from "react";
import styles from "./mainLayout.module.scss";
import { Card, Layout, Menu, Avatar, Button, ConfigProvider } from "antd";
import { useNavigate, useRoutes } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { LogoutOutlined } from "@ant-design/icons";
import { useProfile } from "../context/UserProfile.context";
import constants from "../constants";

const { Header, Content, Footer } = Layout;

const MainLayout = (props: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const onNavigate = (menu: string) => {
    navigate(menu);
  };
  const profileState = useProfile();

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#0e4b99",
          borderRadius: 3,
        },
      }}
    >
      <Layout className={styles.container}>
        <Header
          className={styles.header}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#07264c",
          }}
        >
          <div className={styles.logo}>
            <img src="/logo-stacked.svg" />
          </div>
          <Menu
            theme="dark"
            onClick={(menu) => onNavigate(menu.key)}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#07264c",
            }}
            defaultSelectedKeys={[window.location.pathname]}
            items={[
              {
                key: "/",
                label: `Home`,
              },
              {
                key: "/admin",
                label: `Antreean`,
              },
              {
                key: "/settings-parent",
                label: `Settings`,
                children: [
                  {
                    key: "/settings",
                    label: `Streamer`,
                  },
                ],
              },
            ]}
          />
          <div className={styles.profile}>
            <Avatar src={profileState?.picture} />
            <span>{profileState?.name}</span>
          </div>
          <Button
            type="default"
            style={{ backgroundColor: "transparent", color: "white" }}
            onClick={() => {
              googleLogout();
              localStorage.removeItem("session");
              window.location.reload();
            }}
          >
            <LogoutOutlined />
            Logout
          </Button>
        </Header>
        <Content className={styles.contentContainer}>
          <div></div>
          <Card className={styles.main}>{props.children}</Card>
          <div></div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#1f1f1f",
            color: "white",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h5 style={{ margin: "0.5rem" }}>
            Powered by Antree 2023 v{constants.version}
          </h5>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;
