import React, { useState } from "react";
import { Queue, QueueDetail } from "../../types";
import {
  Avatar,
  Button,
  ColorPicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Popover,
  Radio,
  Result,
  Switch,
  Tooltip,
} from "antd";
import { thousandSeparator } from "../../util/general";
import styles from "./QueueDetail.module.scss";
import {
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  LinkOutlined,
  MinusOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SwapOutlined,
  UpOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import * as uuid from "uuid";
import _ from "lodash";
import formatDistance from "date-fns/formatDistance";
import id from "date-fns/locale/id";
import format from "date-fns/format";
import CopyToClipboard from "react-copy-to-clipboard";

const QueueDetailTab = (props: {
  data: QueueDetail;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onRowAction: (
    detailId: string,
    queueId: string,
    action: "add" | "subtract" | "delete"
  ) => void;
  onAdded: (detailId: string, at: number, newItem: Queue) => void;
  onItemMoved: (detailId: string, item: Queue, moveTo: number) => void;
}) => {
  const [addNewState, setAddNewState] = useState<{
    showPopup: boolean;
    newQueue: Queue | null;
    at: number;
  }>({
    showPopup: false,
    newQueue: {
      id: uuid.v4(),
      data: {},
      email: "",
      name: "",
      qty: 1,
      at: 0,
      totalDonate: 0,
      message: "",
    },
    at: props.data?.queue?.length ?? 0,
  });
  const [overlayLinkState, setOverlayLinkState] = useState<{
    showModal: boolean;
    showAlert: boolean;
    form: {
      layout: "vertical" | "horizontal";
      weight: "normal" | "bold";
      color: "white";
      border: boolean;
    };
  }>({
    showModal: false,
    showAlert: false,
    form: {
      layout: "horizontal",
      weight: "normal",
      color: "white",
      border: true,
    },
  });
  const [moveState, setMoveState] = useState<{
    isMoving: boolean;
    item: Queue | null;
    currentHover: Queue | null;
    hoverDirection: "up" | "down" | null;
  }>({
    isMoving: false,
    item: null,
    currentHover: null,
    hoverDirection: null,
  });

  const renderOverlayLink = () => {
    return `${process.env.REACT_APP_WEB_BASE}/display/${props.data.id}/${
      overlayLinkState?.form.layout ?? "horizontal"
    }?color=${encodeURIComponent(
      overlayLinkState?.form.color ?? "#fff"
    )}&fontweight=${overlayLinkState?.form.weight ?? "bold"}&border=${
      overlayLinkState.form.border
    }`;
  };

  const onAddClicked = () => {
    setAddNewState({
      showPopup: true,
      newQueue: {
        id: uuid.v4(),
        data: {},
        email: "",
        name: "",
        qty: 1,
        at: 0,
        totalDonate: 0,
        message: "",
      },
      at: props.data.queue?.length ?? 0,
    });
  };

  const resetForm = () => {
    setAddNewState({
      showPopup: false,
      newQueue: {
        id: uuid.v4(),
        data: {},
        email: "",
        name: "",
        qty: 1,
        at: 0,
        totalDonate: 0,
        message: "",
      },
      at: props.data.queue?.length ?? 0,
    });
  };

  const onAddSaved = () => {
    props.onAdded(props.data.id, addNewState.at, {
      ...(addNewState.newQueue as Queue),
      at: Date.now(),
    });
    resetForm();
  };

  const onFormChange = (field: keyof Queue | string, value: any) => {
    setAddNewState((prev) => _.set(_.cloneDeep(prev), field, value));
  };

  const onShowOverlay = () => {
    setOverlayLinkState((prev) => {
      return {
        ...prev,
        showAlert: false,
        showModal: true,
      };
    });
  };

  const onHideOverlay = () => {
    setOverlayLinkState((prev) => {
      return {
        ...prev,
        showAlert: false,
        showModal: false,
      };
    });
  };

  const onShowOverlayAlert = () => {
    setOverlayLinkState((prev) => {
      return {
        ...prev,
        showAlert: true,
        showModal: false,
      };
    });
  };

  const onHideOverlayAlert = () => {
    setOverlayLinkState((prev) => {
      return {
        ...prev,
        showAlert: false,
        showModal: false,
      };
    });
  };

  const onOverlayFormChanged = (field: string, value: any) => {
    setOverlayLinkState((prev) => _.set(_.cloneDeep(prev), field, value));
  };

  const onMoving = (item: Queue) => {
    setMoveState({
      isMoving: true,
      item,
      currentHover: null,
      hoverDirection: null,
    });
  };

  const onCancel = () => {
    setMoveState({
      isMoving: false,
      item: null,
      currentHover: null,
      hoverDirection: null,
    });
  };

  const onMouseOver = (item: Queue, hoverDirection: "up" | "down") => {
    setMoveState((prev) => ({
      ...prev,
      currentHover: item,
      hoverDirection: hoverDirection,
    }));
  };

  const onMouseOut = () => {
    setMoveState((prev) => ({
      ...prev,
      currentHover: null,
      hoverDirection: null,
    }));
  };
  const overlayLink = renderOverlayLink();

  return (
    <div className={styles.container}>
      <Modal
        open={addNewState.showPopup}
        onCancel={resetForm}
        onOk={onAddSaved}
      >
        <Form
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <Form.Item
            label="Nama"
            rules={[{ required: true, message: "inputkan nama pengantree!" }]}
            required
          >
            <Input
              value={addNewState.newQueue?.name}
              onChange={(e) => onFormChange("newQueue.name", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              value={addNewState.newQueue?.email}
              onChange={(e) => onFormChange("newQueue.email", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Jumlah Item antrian" required>
            <InputNumber
              value={addNewState.newQueue?.qty ?? 1}
              onChange={(e) => onFormChange("newQueue.qty", e)}
            />
          </Form.Item>
          <Form.Item label="Total Donasi">
            <InputNumber
              value={addNewState.newQueue?.totalDonate ?? 0}
              onChange={(e) => onFormChange("newQueue.totalDonate", e)}
            />
          </Form.Item>
          {props.data?.fields?.map((t) => {
            return (
              <Form.Item key={t.name} label={t.name}>
                <Input
                  value={addNewState.newQueue?.data[t.name]}
                  onChange={(e) =>
                    onFormChange("newQueue.data." + t.name, e.target.value)
                  }
                />
              </Form.Item>
            );
          }) ?? []}
          <Radio.Group
            options={[
              { label: "Di Awal", value: "begin" },
              { label: "Di Akhir", value: "end" },
            ]}
            onChange={(e) =>
              onFormChange(
                "at",
                e.target.value === "begin" ? 0 : props.data.queue?.length ?? 0
              )
            }
            value={addNewState.at > 0 ? "end" : "begin"}
            optionType="button"
            buttonStyle="solid"
          />
        </Form>
      </Modal>
      <Modal
        open={overlayLinkState.showModal}
        onCancel={onHideOverlay}
        footer={<></>}
      >
        <div className={styles.overlayModal}>
          <iframe
            src={overlayLink}
            style={{
              width: "100%",
              height: "15rem",
              border: "none",
            }}
            title="overlay preview"
          />
          <div className={styles.overlaylink}>
            <LinkOutlined /> {overlayLink}
          </div>
          <CopyToClipboard text={overlayLink} onCopy={() => {}}>
            <Button size="small" className={styles.copy}>
              <CopyOutlined /> copy overlay link
            </Button>
          </CopyToClipboard>

          <Form
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
          >
            <Form.Item label="Layout">
              <Radio.Group
                options={[
                  { label: "Vertical", value: "vertical" },
                  { label: "Horizontal", value: "horizontal" },
                ]}
                onChange={(e) =>
                  onOverlayFormChanged("form.layout", e.target.value)
                }
                value={overlayLinkState.form.layout}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item label="Font weight">
              <Radio.Group
                options={[
                  { label: "Normal", value: "normal" },
                  { label: "Bold", value: "bold" },
                ]}
                onChange={(e) =>
                  onOverlayFormChanged("form.weight", e.target.value)
                }
                value={overlayLinkState.form.weight}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item label="Font Color">
              <ColorPicker
                value={overlayLinkState.form.color}
                onChangeComplete={(e) =>
                  onOverlayFormChanged("form.color", e.toHexString())
                }
              />
            </Form.Item>
            <Form.Item label="Border">
              <Switch
                onChange={(e) => onOverlayFormChanged("form.border", e)}
                checked={overlayLinkState.form.border}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        open={overlayLinkState.showAlert}
        onCancel={onHideOverlayAlert}
        footer={<></>}
      >
        <Result
          status="warning"
          title={
            <div>
              <h5>Halaman ini berisi informasi rahasia</h5>
              pastikan anda tidak sedang menunjukan halaman ini di live stream
              atau konten
            </div>
          }
          extra={
            <Button type="primary" onClick={onShowOverlay}>
              Saya mengerti
            </Button>
          }
        />
      </Modal>
      <div className={styles.tools}>
        <Button onClick={() => props.onEdit(props.data.id)}>
          <SettingOutlined /> setting
        </Button>
        <Button onClick={onShowOverlayAlert}>
          <LinkOutlined /> overlay
        </Button>
        <Button onClick={() => props.onDelete(props.data.id)} danger>
          <DeleteOutlined /> hapus antree-an
        </Button>
      </div>
      <Descriptions layout="horizontal" column={1} bordered size="small">
        <Descriptions.Item label="Harga">{`${thousandSeparator(
          props.data.price
        )}`}</Descriptions.Item>
        <Descriptions.Item
          label={
            <div>
              Command{" "}
              <Tooltip
                placement="topLeft"
                title="petunjuk lengkap integrasi platform donasi, cek di menu Settings > Streamer"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          }
        >
          {props.data.command}{" "}
          {props.data.fields.map((t) => t.name + "=123abc").join("&")}
        </Descriptions.Item>
        <Descriptions.Item label="Data">
          {props.data.fields.map((t) => t.name).join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Link Display">
          <CopyToClipboard
            text={`${process.env.REACT_APP_WEB_BASE}/display/${props.data.id}`}
            onCopy={() => {}}
          >
            <Button type="link" size="small">
              <CopyOutlined /> {process.env.REACT_APP_WEB_BASE}/display/
              {props.data.id}
            </Button>
          </CopyToClipboard>
        </Descriptions.Item>
      </Descriptions>
      <List
        header={
          <div className={styles.listHeader}>
            <span>Antree-an : </span>
            <Button
              type="default"
              size="small"
              className={styles.addNew}
              title="tambah pengantree baru"
              onClick={onAddClicked}
            >
              <PlusOutlined />
            </Button>
          </div>
        }
        bordered
        itemLayout="horizontal"
        dataSource={props.data?.queue ?? []}
        renderItem={(item, index) => {
          const buttons =
            moveState.isMoving && moveState?.item?.id === item.id
              ? [
                  index !== 0 ? (
                    <Button
                      key={"top"}
                      size="middle"
                      onClick={() => {
                        props.onItemMoved(
                          props.data.id,
                          moveState.item as Queue,
                          0
                        );
                        onCancel();
                      }}
                      title="pindahkan ke paling awal"
                    >
                      <VerticalAlignTopOutlined />
                    </Button>
                  ) : null,
                  props.data.queue?.length - 1 !== index ? (
                    <Button
                      key={"bottom"}
                      size="middle"
                      onClick={() => {
                        props.onItemMoved(
                          props.data.id,
                          moveState.item as Queue,
                          props.data.queue?.length - 1
                        );
                        onCancel();
                      }}
                      title="pindahkan ke paling akhir"
                    >
                      <VerticalAlignBottomOutlined />
                    </Button>
                  ) : null,
                  <Button
                    key={"move"}
                    size="middle"
                    onClick={onCancel}
                    title="batalkan memindah"
                    danger
                  >
                    <CloseCircleOutlined />
                  </Button>,
                ]
              : moveState.isMoving && moveState?.item?.id !== item.id
              ? [
                  index === 0 ? (
                    <Button
                      key={"moveup"}
                      size="small"
                      onMouseOver={() => onMouseOver(item, "up")}
                      onClick={() => {
                        props.onItemMoved(
                          props.data.id,
                          moveState.item as Queue,
                          index
                        );
                        onCancel();
                      }}
                      onMouseLeave={onMouseOut}
                      title="pindahkan ke sini"
                    >
                      <UpOutlined />
                    </Button>
                  ) : null,
                  <Button
                    key={"movedown"}
                    size="small"
                    onClick={() => {
                      props.onItemMoved(
                        props.data.id,
                        moveState.item as Queue,
                        index + 1
                      );
                      onCancel();
                    }}
                    onMouseOver={() => onMouseOver(item, "down")}
                    onMouseLeave={onMouseOut}
                    title="pindahkan ke sini"
                  >
                    <DownOutlined />
                  </Button>,
                ]
              : [
                  <Button
                    key={"move"}
                    size="small"
                    onClick={() => onMoving(item)}
                    title="pindahkan"
                  >
                    <SwapOutlined />
                  </Button>,
                  <Button
                    key={"subtract"}
                    size="small"
                    onClick={() =>
                      props.onRowAction(props.data.id, item.id, "subtract")
                    }
                  >
                    <MinusOutlined />
                  </Button>,
                  <div key="qty">{item.qty}</div>,
                  <Button
                    key={"add"}
                    size="small"
                    onClick={() =>
                      props.onRowAction(props.data.id, item.id, "add")
                    }
                  >
                    <PlusOutlined />
                  </Button>,
                ];
          return (
            <List.Item
              actions={buttons}
              className={
                moveState.isMoving && moveState?.item?.id === item.id
                  ? styles.itemMoving
                  : ""
              }
              style={{
                borderBottom:
                  moveState.isMoving &&
                  moveState.hoverDirection === "down" &&
                  moveState.item?.id !== item.id &&
                  moveState.currentHover?.id === item.id
                    ? "3px solid #2f54eb"
                    : "1px solid transparent",
                borderTop:
                  moveState.isMoving &&
                  moveState.hoverDirection === "up" &&
                  moveState.item?.id !== item.id &&
                  moveState.currentHover?.id === item.id
                    ? "3px solid #2f54eb"
                    : "1px solid transparent",
                transition: "border 200ms ease-out",
              }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar size={"large"} style={{ backgroundColor: "#2963a6" }}>
                    {index + 1}
                  </Avatar>
                }
                title={item.name}
                description={
                  <div>
                    {Object.entries(item.data ?? {})
                      .map((t) => `${t[0]} : ${t[1]}`)
                      .join("  |  ")}{" "}
                    | Total Donasi : Rp{thousandSeparator(item.totalDonate)},- |
                    <span
                      title={format(new Date(item.at), "dd MMM yyyy HH:mm")}
                    >
                      {" "}
                      {formatDistance(item.at, Date.now(), {
                        locale: id,
                        includeSeconds: true,
                      })}{" "}
                      yang lalu
                    </span>{" "}
                    | Pesan : "{item.message}"
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default QueueDetailTab;
