import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Home from "./pages/home/Home";
import QueueAdmin from "./pages/admin/QueueAdmin";
import Login from "./pages/login/Login";
import ProtectedRoute from "./util/protectedRoute";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainLayout from "./layout/mainLayout";
import UserProfileContext from "./context/UserProfile.context";
import SettingsPage from "./pages/settings/Settings.page";
import HorizontalOverlay from "./pages/overlay/Horizontal";
import VerticalOverlay from "./pages/overlay/Vertical";
import SubsConditional from "./util/subsConditionalRenderer";
import InactivePage from "./pages/inactive/incative";
import QueuePublicView from "./pages/queuePublicView/QueuePublicView";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <UserProfileContext>
        <Home />
      </UserProfileContext>
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute>
        <UserProfileContext>
          <SubsConditional
            active={<QueueAdmin />}
            inactive={<InactivePage />}
          />
        </UserProfileContext>
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <UserProfileContext>
          <SubsConditional
            active={<SettingsPage />}
            inactive={<InactivePage />}
          />
        </UserProfileContext>
      </ProtectedRoute>
    ),
  },
  {
    path: "/display/:queueId/horizontal",
    element: <HorizontalOverlay />,
  },
  {
    path: "/display/:queueId/vertical",
    element: <VerticalOverlay />,
  },
  {
    path: "/display/:queueId",
    element: <QueuePublicView />,
  },
  {
    path: "/login",
    element: (
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_OAUTH_CLIENT_ID as string}
      >
        <Login />
      </GoogleOAuthProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
