import React from "react";
import styles from "./homeLayout.module.scss";
import { Card, Layout, Menu, Avatar, Button, ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { useProfile } from "../context/UserProfile.context";
import constants from "../constants";

const { Header, Content, Footer } = Layout;

const HomeLayout = (props: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const onNavigate = (menu: string) => {
    navigate(menu);
  };
  const profileState = useProfile();
  const menuItems = [
    {
      key: "/",
      label: `Home`,
    },
  ];

  if (profileState?.id) {
    menuItems.push({
      key: "/admin",
      label: `Antreean`,
    });
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#0e4b99",
          borderRadius: 3,
        },
      }}
    >
      <Layout className={styles.container}>
        <Header
          className={styles.header}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            paddingLeft: "14vw",
            paddingRight: "14vw",
          }}
        >
          <div className={styles.logo}>
            <img src="/logo-primary.png" />
          </div>
          <Menu
            onClick={(menu) => onNavigate(menu.key)}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "transparent",
            }}
            items={menuItems}
          />

          <div className={styles.profile}>
            {profileState?.id ? (
              <>
                <Avatar src={profileState?.picture} />
                <span>{profileState?.name}</span>
              </>
            ) : null}
          </div>

          {profileState?.id ? (
            <Button
              type="default"
              onClick={() => {
                googleLogout();
                localStorage.removeItem("session");
                window.location.reload();
              }}
            >
              <LogoutOutlined />
              Logout
            </Button>
          ) : (
            <Button
              type="default"
              onClick={() => {
                localStorage.removeItem("session");
                navigate("/login");
              }}
            >
              <LoginOutlined />
              Login
            </Button>
          )}
        </Header>
        <Content className={styles.contentContainer}>
          <div></div>
          <div className={styles.main}>{props.children}</div>
          <div></div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#1f1f1f",
            color: "white",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h5 style={{ margin: "0.5rem" }}>
            Powered by Antree 2023 v{constants.version}
          </h5>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default HomeLayout;
