import React from "react";
import { SubscriptionStatus } from "../types";
import { useProfile } from "../context/UserProfile.context";
import { Spin } from "antd";
const SubsConditional = (
  props: React.PropsWithChildren<{
    active: React.ReactNode;
    inactive: React.ReactNode;
  }>
) => {
  const profileContext = useProfile();
  let element: React.ReactNode;
  if (!profileContext)
    element = (
      <div style={{ position: "fixed", top: "50vh", right: "50vw" }}>
        <Spin size="large" />;
      </div>
    );
  else {
    element =
      profileContext.subscriptionStatus !== SubscriptionStatus.INACTIVE
        ? props.active
        : props.inactive;
  }
  return <>{element}</>;
};
export default SubsConditional;
