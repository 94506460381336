import { Button, Collapse, Descriptions, Result, Tabs } from "antd";
import PrivateContentPopup from "../../components/PrivateContentPopup";
import MainLayout from "../../layout/mainLayout";
import { useProfile } from "../../context/UserProfile.context";
import styles from "./inactive.module.scss";
import { Helmet } from "react-helmet";
const InactivePage = () => {
  const profileContext = useProfile();

  return (
    <MainLayout>
      <Helmet>
        <title>Admin | Belum berlangganan</title>
      </Helmet>
      <Result
        status="403"
        title="Kamu belum berlangganan"
        subTitle={
          <div className={styles.container}>
            <p>
              Hai {profileContext?.name}, terimakasih sudah mendaftar di{" "}
              <strong>Antree</strong>
            </p>
            <p>
              layanan ini masih dalam tahap pengembangan, hanya user tertentu
              yang saat ini dapat menggunakan fitur Antree.
            </p>
            <p>
              kamu bisa mengajukan request free trial dengan mengisi form ini
            </p>
          </div>
        }
        extra={
          <Button type="primary" href="https://forms.gle/DKJw8D1emxwU1hgY8">
            Request Free Trial
          </Button>
        }
      />
    </MainLayout>
  );
};

export default InactivePage;
