import React, { createContext, useContext, useEffect, useState } from "react";
import { ProfileResponse } from "../types";
import { backendAPI } from "../util/api";

const ProfileUserContext = createContext<ProfileResponse | null>(null);

export const useProfile = () => {
  return useContext(ProfileUserContext);
};

const UserProfileContext = (props: React.PropsWithChildren) => {
  const [profileState, setProfileState] = useState<ProfileResponse | null>(
    null
  );

  useEffect(() => {
    Promise.allSettled([
      backendAPI.get<ProfileResponse>("/auth/profile", {
        validateStatus: () => true,
      }),
    ])
      .then(([res]) => {
        if (res.status === "fulfilled") setProfileState(res.value.data);
      })
      .catch(() => {
        setProfileState(null);
      });
  }, []);

  return (
    <ProfileUserContext.Provider value={profileState}>
      {props.children}
    </ProfileUserContext.Provider>
  );
};

export default UserProfileContext;
