import _ from "lodash";
import { Queue, QueueDetail } from "../types";
import { backendAPI } from "../util/api";

const getMyQueue = async () => {
  return await backendAPI.get<QueueDetail[]>("/queue");
};
const getQueueById = async (id: string) => {
  return await backendAPI.get<QueueDetail>("/queue/" + id);
};

const createQueue = async (data: Partial<QueueDetail>) => {
  return await backendAPI.post<QueueDetail>("/queue", data);
};

const updateQueueDetail = async (id: string, data: Partial<QueueDetail>) => {
  return await backendAPI.patch<QueueDetail>(
    "/queue/" + id,
    _.omit(data, "queue")
  );
};

const updateQueue = async (id: string, queue: Queue[]) => {
  return await backendAPI.patch<QueueDetail>("/queue/" + id + "/queue", queue);
};

const deleteQueue = async (id: string) => {
  return await backendAPI.delete<QueueDetail>("/queue/" + id);
};

const antreeAPIs = {
  getMyQueue,
  getQueueById,
  createQueue,
  updateQueueDetail,
  updateQueue,
  deleteQueue,
};

export default antreeAPIs;
