import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginResponse, ProfileResponse } from "../types";
import axios from "axios";
const ProtectedRoute = (props: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("session");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }

    axios
      .get<ProfileResponse>(
        (process.env.REACT_APP_API_BASE as string) + "/auth/profile",
        {
          headers: {
            Authorization: "Bearer " + userToken,
          },
        }
      )
      .then((res) => {
        setIsLoggedIn(true);
        setProfile(res.data);
      })
      .catch(() => {
        navigate("/login");
      });
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return <>{isLoggedIn ? props.children : null}</>;
};
export default ProtectedRoute;
