import { useEffect, useState } from "react";
import { QueueDetail } from "../../types";
import { Helmet } from "react-helmet";
import HomeLayout from "../../layout/homeLayout";
import { Avatar, Descriptions, List } from "antd";
import { thousandSeparator } from "../../util/general";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import id from "date-fns/locale/id";
import { useParams } from "react-router-dom";
import antreeAPIs from "../../api/atreeAPI";
import useWebSocket from "react-use-websocket";
import _ from "lodash";
import styles from "./QueuePublicView.module.scss";

const QueuePublicView = () => {
  const params = useParams();
  const [queueDetail, setQueueDetail] = useState<QueueDetail | null>(null);

  useEffect(() => {
    if (!params.queueId) return;

    antreeAPIs
      .getQueueById(params.queueId)
      .then((res) => setQueueDetail(res.data));
  }, [params.queueId]);

  const { lastMessage } = useWebSocket(
    `${
      process.env.REACT_APP_WEBHOOK_BASE as string
    }/ws-init?token=${localStorage.getItem("session")}&as=overlay&id=${
      params.queueId
    }`
  );
  useEffect(() => {
    let messageJson = {} as any;

    try {
      messageJson = JSON.parse(lastMessage?.data);
    } catch (error) {}

    if (messageJson.type === "queue") {
      setQueueDetail((prev) =>
        prev ? _.cloneDeep({ ...prev, queue: messageJson.data }) : null
      );
    }
  }, [lastMessage]);
  return (
    <HomeLayout>
      <Helmet>
        <title>{queueDetail?.name || "Antreean"}</title>
      </Helmet>
      <div className={styles.container}>
        <h1>{queueDetail?.name}</h1>

        <Descriptions layout="horizontal" column={1} bordered size="small">
          <Descriptions.Item label="Harga">{`${thousandSeparator(
            queueDetail?.price ?? 0
          )}`}</Descriptions.Item>
          <Descriptions.Item label="Command">
            {queueDetail?.command}{" "}
            {queueDetail?.fields.map((t) => t.name + "=123abc").join("&")}
          </Descriptions.Item>
          <Descriptions.Item label="Data">
            {queueDetail?.fields.map((t) => t.name).join(", ")}
          </Descriptions.Item>
        </Descriptions>
        <List
          bordered
          itemLayout="horizontal"
          dataSource={queueDetail?.queue ?? []}
          renderItem={(item, index) => {
            return (
              <List.Item
                actions={[
                  <div key="qty">
                    <strong>{item.qty}</strong>
                  </div>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={"large"}
                      style={{ backgroundColor: "#2963a6" }}
                    >
                      {index + 1}
                    </Avatar>
                  }
                  title={item.name}
                  description={
                    <div>
                      <span
                        title={format(new Date(item.at), "dd MMM yyyy HH:mm")}
                      >
                        {" "}
                        {formatDistance(item.at, Date.now(), {
                          locale: id,
                          includeSeconds: true,
                        })}{" "}
                        yang lalu
                      </span>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      </div>
    </HomeLayout>
  );
};

export default QueuePublicView;
